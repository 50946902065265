<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Timeclock Manager</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" to="/scotswork/timeclock/detail" exact icon>
              <v-icon>fal fa-eye</v-icon>
            </v-btn>
          </template>
          <span>View Timeclock scan detail</span>
        </v-tooltip>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              <v-icon>{{ filter !== 'All' ? 'fas' : 'fal' }} fa-filter</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item :disabled="filter === 'All'" @click="filter = 'All'">
              <v-list-item-title>Show All Configured Timeclocks</v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="filter === 'Online'" @click="filter = 'Online'">
              <v-list-item-title>Show Only Online Timeclocks</v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="filter === 'Offline'" @click="filter = 'Offline'">
              <v-list-item-title>Show Only Offline Timeclocks</v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="filter === 'Unconfigured'" @click="filter = 'Unconfigured'">
              <v-list-item-title>Show Only Unconfigured Timeclocks</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-dialog v-if="user.username === 'jon.moon'" v-model="newTimeclockDialog" width="400">
          <template v-slot:activator="{ on: dialogOn }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltipOn }">
                <v-btn v-on="{ ...dialogOn, ...tooltipOn }" icon>
                  <v-icon>fal fa-plus</v-icon>
                </v-btn>
              </template>
              <span>Add a new timeclock location</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>New Timeclock Location</v-card-title>
            <v-card-text>
              <v-text-field v-model="newTimeclockLocation" label="Location Name"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="newTimeclockDialog = false">Cancel</v-btn>
              <v-btn text color="success" @click="saveNewTimeclock()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table :items="timeclocks" :headers="headers" :items-per-page="timeclocks.length"  :footer-props="{ 'items-per-page-options': [20, 30] }" @dblclick:row="(e, { item }) => { loadDetail(item._id) }">
        <template v-slot:item.ping="{ item }">
          <v-alert v-if="!item.locked" icon="fas fa-exclamation-triangle" dense style="margin-bottom:0">-- Not Configured --</v-alert>
          <v-alert v-else-if="item.pingSeconds > 300" type="error" dense style="margin-bottom:0">{{ item.pingTimeAgo }}</v-alert>
          <v-alert v-else-if="item.pingSeconds > 120" type="warning" dense style="margin-bottom:0">{{ stringFormatDateToday(item.ping) }}</v-alert>
          <v-alert v-else-if="item.ping" type="success" dense style="margin-bottom:0">{{ stringFormatDateToday(item.ping) }}</v-alert>
          <v-alert v-else type="error" dense style="margin-bottom:0">Configured; never pinged server</v-alert>
        </template>
        <template v-slot:item.scan="{ item }">{{ stringFormatDateToday(item.scan) }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon @click="loadDetail(item._id)">
                <v-icon>fal fa-eye</v-icon>
              </v-btn>
            </template>
            <span>View timeclock scan detail for {{ item.location }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from '@vue/composition-api'
import { stringFormatDate, stringFormatDateToday } from '../../../helpers/formatters'
import { getTimeDiff } from '../../../helpers/diffs'
export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const timeclocks = ref([])
    const headers = ref([
      { text: 'Location', value: 'location' },
      { text: 'IP Address', value: 'ip' },
      { text: 'Last Ping', value: 'ping' },
      { text: 'Last Scan', value: 'scan' },
      { text: '', value: 'actions' }
    ])
    const filter = computed({
      get: () => root.$store.state['work-study'].timeclockFilter,
      set: (val) => {
        root.$store.commit('work-study/setTimeclockFilter', val)
        load()
      }
    })

    const service = root.$feathers.service('timeclock/setup')

    function load () {
      const query = { locked: true, $limit: 50, $sort: { location: 1 } }
      const dt = new Date()
      dt.setMinutes(dt.getMinutes() - 5)
      switch (filter.value) {
        case 'Online':
          query.ping = { $gte: dt }
          break
        case 'Offline':
          query.ping = { $lte: dt }
          break
        case 'Unconfigured':
          query.locked = false
          break
      }
      service.find({ query }).then(({ data }) => {
        timeclocks.value = data
        update()
        if (interval == null) {
          interval = setInterval(load, 30000)
        }
      })
    }
    function update () {
      for (let i = 0; i < timeclocks.value.length; i++) {
        if ('ping' in timeclocks.value[i]) {
          let temp = { ...timeclocks.value[i] }
          temp.pingSeconds = getTimeDiff(new Date(), temp.ping, true)
          const diff = getTimeDiff(new Date(), temp.ping).split(',')
          temp.pingTimeAgo = 'More than ' + diff[0] + (diff.length > 1 ? ' ago' : '')
          timeclocks.value.splice(i, 1, temp)
        }
      }
    }
    // const onPatchedListener = (data) => {
    //   for (let i = 0; i < timeclocks.value.length; i++) {
    //     if (timeclocks.value[i]._id === data._id) {
    //       data.pingSeconds = 0
    //       data.pingTimeAgo = '0 seconds ago'
    //       timeclocks.value.splice(i, 1, { ...data, pingSeconds: 0, pingTimeAgo: '0 seconds ago' })
    //     }
    //   }
    // }

    let interval = null
    onMounted(() => {
      load()
      // service.on('patched', onPatchedListener)
    })
    onBeforeUnmount(() => {
      // service.removeListener('patched', onPatchedListener)
      clearInterval(interval)
    })

    const newTimeclockDialog = ref(false)
    const newTimeclockLocation = ref('')
    function saveNewTimeclock () {
      service.create({ location: newTimeclockLocation.value, locked: false }).then(() => {
        newTimeclockDialog.value = false
        load()
      })
    }

    function loadDetail (id) {
      root.$store.commit('work-study/setTimeclockFilter', { timeclocks: [id], student: '', dates: [] })
      root.$router.push('/scotswork/timeclock/detail')
    }

    return {
      user,
      roles,
      timeclocks,
      headers,
      filter,
      newTimeclockDialog,
      newTimeclockLocation,
      saveNewTimeclock,
      loadDetail,
      stringFormatDate,
      stringFormatDateToday
    }
  }
}
</script>
